import React from "react"
import { Link } from "gatsby"

import Layout from "/src/components/layout"
import Biogeo from "/src/components/img/biogeo"
import ImgCon from "/src/components/img-con"
import BtnWrap from "/src/components/btn-wrap"
import SEO from "/src/components/seo"

export default ({ data }) => (
  <Layout>
    <SEO title="Speciace, její mechanismy a typy" />
    <h1>Speciace, její mechanismy a typy</h1>
    <p><strong>Speciace</strong> je evolučn&iacute; proces vzniku druhů. Skupina uvnitř dan&eacute; populace se odděl&iacute; a vytvoř&iacute; nov&yacute; druh. Rozli&scaron;ujeme tři z&aacute;kladn&iacute; procesy vzniku druhů:</p>
<ul>
<li><strong>&scaron;těpn&aacute; speciace</strong> (vznik &scaron;těpen&iacute;m mateřsk&eacute;ho druhu na dva nov&eacute;)</li>
<li><strong>hybridizace</strong> (vznik nov&eacute;ho druhu kř&iacute;žen&iacute;m, např. u kř&iacute;dlatek)</li>
<li><strong>fyletick&aacute; speciace</strong> (mateřsk&yacute; druh se transformuje na druh odli&scaron;n&yacute;, na tzv. chronodruh)</li>
</ul>
<hr />
<h2>Mechanismy speciace</h2>
<p>Mezi kl&iacute;čov&eacute; mechanismy a pojmy speciace patř&iacute;:</p>
<ul>
<li><strong>mutace</strong><strong>genu</strong></li>
<li><strong>genetick&yacute; drift</strong></li>
<li><strong>př&iacute;rodn&iacute; v&yacute;běr</strong></li>
<li><strong>tok genů</strong></li>
</ul>
<h3>Mutace genu</h3>
<p>Každ&yacute; gen může m&iacute;t jednu či v&iacute;ce forem, neboli alel. Tyto formy vznikaj&iacute; mutacemi a maj&iacute; různ&eacute; vněj&scaron;&iacute; projevy. Li&scaron;&iacute; se mezi jedinci i mezi populacemi.</p>
<p>Někter&eacute; znaky mohou b&yacute;t k&oacute;dovan&eacute; pouze jedn&iacute;m genem, př&iacute;kladem může b&yacute;t pravotočivost a levotočivost ulit u rodu <em>Vertigo</em> (spadaj&iacute; do tř&iacute;dy plžů). Ve fauně ČR se vyskytuj&iacute; 2 levotočiv&iacute; a 7 pravotočiv&yacute;ch.</p>
<p>Frekvence alel v populaci se měn&iacute; v důsledku:</p>
<ul>
<li>genetick&eacute;ho driftu</li>
<li>př&iacute;rodn&iacute;ho v&yacute;běru</li>
<li>tou genů</li>
</ul>
<h3>Genetick&yacute; drift</h3>
<p>Pojem genetick&yacute; drift popisuje n&aacute;hodn&eacute; změny v genetick&eacute;m složen&iacute; populac&iacute;. Frekvence alel v populaci se měn&iacute; důsledkem n&aacute;hodn&eacute;ho přež&iacute;v&aacute;n&iacute; a n&aacute;sledn&eacute; reprodukčn&iacute; &uacute;spě&scaron;nosti jedinců.</p>
<p>Č&iacute;m men&scaron;&iacute; populace je, t&iacute;m rychleji se nějak&aacute; n&aacute;hodně se měn&iacute;c&iacute; alela zafixuje, tedy dos&aacute;hne 100%, nebo 0% frekvence. Proto m&aacute; genetick&yacute; drift relativně mal&yacute; vliv u velk&yacute;ch populac&iacute;, ale naopak velk&yacute; u populac&iacute;ch mal&yacute;ch.</p>
<h4>Efekt hrdla l&aacute;hve (Bottle-neck effect)</h4>
<p>Bottle-neck effect (poče&scaron;těně efekt hrdla l&aacute;hve) je jev, kdy dojde k v&yacute;znamn&eacute;mu posunu v genetick&eacute;m driftu vlivem drastick&eacute;ho &uacute;bytku jedinců v populaci (např. vym&iacute;r&aacute;n&iacute; či izolace mal&eacute; skupiny v nov&eacute;m prostřed&iacute;. Hrdlem l&aacute;hve totiž n&aacute;hodně propadnou jedinci s převahou jedn&eacute; varianty měn&iacute;c&iacute; se alely, kter&aacute; se pak nav&iacute;c vlivem men&scaron;&iacute; populace rychleji fixuje.</p>
<p>Pokud byly např&iacute;klad dř&iacute;ve v populaci v rovnov&aacute;ze jedinci s černou a b&iacute;lou srst&iacute;, vlivem bottle-neck efektu, kdy pomysln&yacute;m hrdlem l&aacute;hve n&aacute;hodou propadnou v&iacute;ce jedinci s černou srst&iacute;, mohou v pozděj&scaron;&iacute;ch generac&iacute;ch dominovat jedinci pr&aacute;vě s černou srst&iacute;.</p>
<h3 id="prirodni-vyber">Př&iacute;rodn&iacute; v&yacute;běr</h3>
<p>Př&iacute;rodn&iacute; v&yacute;běr způsobuje změny v populaci v reakci na působen&iacute; podm&iacute;nek prostřed&iacute;, což zahrnuje i mezi- a vnitrodruhovou kompetici. Někter&eacute; alely totiž zvy&scaron;uj&iacute; pravděpodobnost přežit&iacute; nebo reprodukčn&iacute; &uacute;spě&scaron;nost sv&yacute;ch nositelů a t&iacute;m se &scaron;&iacute;ř&iacute; v populaci na &uacute;kor jin&yacute;ch, neužitečn&yacute;ch alel.</p>
<p>Pokud jsou dvě populace odděleny, často na ně působ&iacute; odli&scaron;n&eacute; selekčn&iacute; tlaky, což může v&eacute;st ke speciaci. Nejen proto je př&iacute;rodn&iacute; v&yacute;běr z&aacute;sadn&iacute;m speciačn&iacute;m mechanismem, a to u mal&yacute;ch i velk&yacute;ch populac&iacute;.</p>
<p>Zaj&iacute;mav&yacute;m a n&aacute;zorn&yacute;m př&iacute;kladem př&iacute;rodn&iacute;ho v&yacute;běru je <a href="https://www.idnes.cz/technet/veda/cesti-a-britsti-vedci-objevili-duvod-drasticke-premeny-bileho-motyla-na-muru.A110415_1567100_veda_vse" target="_blank" rel="noreferrer noopener">průmyslov&yacute; melanismus</a>.</p>
<h4>Typy př&iacute;rodn&iacute;ho v&yacute;běru</h4>
<p>V z&aacute;kladu lze rozli&scaron;it tři z&aacute;kladn&iacute; typy př&iacute;rodn&iacute;ho v&yacute;běru:</p>
<ul>
<li><strong>stabilizačn&iacute; selekce</strong> (př&iacute;rodn&iacute; v&yacute;běr působ&iacute;c&iacute; proti extr&eacute;mům)</li>
<li><strong>směrov&aacute; selekce</strong> (př&iacute;rodn&iacute; v&yacute;běr působ&iacute;c&iacute; ve prospěch určit&eacute;ho znaku)</li>
<li><strong>diverzifikačn&iacute; selekce </strong>(selekce působ&iacute;c&iacute; proti průměru)</li>
</ul>
<h3>Tok genů</h3>
<p>Tok genů tlum&iacute; speciaci a br&aacute;n&iacute; odli&scaron;en&iacute; populac&iacute;. Doch&aacute;z&iacute; k němu, pokud někteř&iacute; jedinci dok&aacute;ž&iacute; migrovat mezi 2 populacemi, kter&eacute; se zač&iacute;naj&iacute; odli&scaron;ovat, a pokud se v těchto populac&iacute;ch dok&aacute;ž&iacute; &uacute;spě&scaron;ně množit. T&iacute;mto pak snižuj&iacute; genetick&eacute; rozd&iacute;ly, jenž vznikaj&iacute; mezi těmito populacemi.</p>
<hr />
<h2>Geografick&aacute; variabilita ve speciaci</h2>
<p>Geografick&aacute; variabilita je <strong>odli&scaron;nost jednotliv&yacute;ch populac&iacute; v důsledku</strong>:</p>
<ul>
<li><strong>adaptace </strong>na lok&aacute;ln&iacute; podm&iacute;nky</li>
<li><strong>př&iacute;rodn&iacute;ho v&yacute;běru</strong></li>
<li><strong>omezen&eacute;ho toku genů</strong></li>
</ul>
<p>Častou použ&iacute;van&yacute;m pojmem je <strong>klina</strong>, což je postupn&aacute; změna nějak&eacute;ho znaku pod&eacute;l gradientu prostřed&iacute;.</p>
<p>Geografick&aacute; izolace zmen&scaron;uje vliv toku genů a naopak umocňuje působen&iacute; genetick&eacute;ho driftu a př&iacute;rodn&iacute;ho v&yacute;běru. S geografickou izolac&iacute; může b&yacute;t mimo jin&eacute; spojov&aacute;n bottle-neck efekt (viz zv&yacute;&scaron;e) či třeba efekt zakladatele (viz n&iacute;že).</p>
<h3>Efekt zakladatele (Founder effect)</h3>
<p>Efekt zakladatele je v biogeografii spojov&aacute;n s izolac&iacute; velmi mal&eacute; č&aacute;sti populace vlivem kolonizace nov&eacute;ho prostřed&iacute;. Nejčastěji jde o os&iacute;dlen&iacute; ostrova několika m&aacute;lo jedinci.</p>
<p>V takto mal&eacute; populaci je velmi n&aacute;hodn&yacute; vzorek alel a znaky, kter&eacute; byly pro dan&yacute; druh běžn&eacute;, se mohou st&aacute;t v takto vznikl&eacute; populaci velmi vz&aacute;cn&eacute;.</p>
<hr />
<h2>Typy speciace</h2>
<p>Obecně se rozli&scaron;uj&iacute; až čtyři hlavn&iacute; typy speciace, a to:</p>
<ul>
<li><strong>alopatrick&aacute; speciace</strong></li>
<li><strong>peripatrick&aacute; speciace</strong> (často jako podsouč&aacute;st allopatrick&eacute; speciace)</li>
<li><strong>parapatrick&aacute; speciace</strong></li>
<li><strong>sympatrick&aacute; speciace</strong></li>
</ul>
<h3>Alopatrick&aacute; speciace</h3>
<p>Alopatrick&aacute; speciace (allopatric speciation) vznik&aacute; geografickou izolac&iacute; populac&iacute; a z toho plynouc&iacute;ho přeru&scaron;en&iacute; toku genů a pos&iacute;len&eacute;ho působen&iacute; př&iacute;rodn&iacute;ho v&yacute;běru a genetick&eacute;ho driftu.</p>
<h4>Vikariance a vikariančn&iacute; ud&aacute;lost</h4>
<p>Z&aacute;kladn&iacute;m mechanismem izolace u alopatrie je <strong>vikariance</strong>. Vikarianci lze definovat jako vznik bari&eacute;ry mezi populacemi.</p>
<p>Tento vznik bari&eacute;ry lze pojmenovat jako vikariančn&iacute; ud&aacute;lost. Vlivem t&eacute;to ud&aacute;losti jsou populace odděleny a doch&aacute;z&iacute; k alopatrick&eacute; speciaci. V&yacute;sledkem jsou pak nov&eacute;, vikariantn&iacute; druhy.</p>
<h3>Peripatrick&aacute; speciace</h3>
<p>Peripatrick&aacute; speciace (peripatric speciation) je mnohdy označov&aacute;na jako alopatrick&aacute; speciace v důsledku &scaron;&iacute;řen&iacute;. St&aacute;le jde o speciaci, kter&aacute; je důsledkem geografick&eacute; izolace, ta ale vypl&yacute;v&aacute; z překročen&iacute; bari&eacute;ry a kolonizace nov&eacute;ho &uacute;zem&iacute; jedincem či skupinou jedinců.</p>
<h4>Adaptivn&iacute; radiace</h4>
<p>Adaptivn&iacute; radiace je proces, při němž doch&aacute;z&iacute; k rychl&eacute; diferenciaci určit&eacute; skupiny organismů. Doch&aacute;z&iacute; k adaptaci na využ&iacute;v&aacute;n&iacute; různ&yacute;ch zdrojů a t&iacute;m i k sn&iacute;žen&iacute; konkurenčn&iacute;ho tlaku.</p>
<p>Nejčastěji vznik&aacute; při zaplňov&aacute;n&iacute; n&aacute;hle uvolněn&yacute;ch nik (nejzn&aacute;měj&scaron;&iacute; př&iacute;klad Darwinov&yacute;ch pěnkav na Galap&aacute;g&aacute;ch) nebo třeba vznikem evolučn&iacute; novinky, kter&aacute; umožn&iacute; využ&iacute;t nov&eacute; niky (např. endotermie u pt&aacute;ků a savců).</p>
<h3>Sympatrick&aacute; speciace</h3>
<p>Sympatrick&aacute; speciace (sympatric speciation) je diferenciace populac&iacute; na nov&eacute; druhy i bez existence geografick&eacute; bari&eacute;ry, a to různ&yacute;mi mechanismy:</p>
<ul>
<li><strong>pohlavn&iacute; v&yacute;běr (etologick&aacute; speciace)</strong></li>
<li><strong>disruptivn&iacute; selekce (ekologick&aacute; speciace)</strong></li>
<li><strong>chromozomov&eacute; změny (polyploidie)</strong></li>
</ul>
<h4>Pohlavn&iacute; v&yacute;běr (etologick&aacute; speciace)</h4>
<p>Podstata etologick&eacute; speciace (či pohlavn&iacute;ho v&yacute;běru) spoč&iacute;v&aacute; v omezen&iacute; toku genů t&iacute;m, že určit&eacute; samice začnou preferovat jin&eacute; samce na z&aacute;kladě nějak&eacute;ho jin&eacute;ho sign&aacute;lu. D&iacute;ky tomu doch&aacute;z&iacute; k poměrně rychl&eacute; reprodukčn&iacute; izolaci, a to i přes to, že neexistuj&iacute; re&aacute;ln&eacute; (postzygotick&eacute;) bari&eacute;ry.</p>
<p>Nejčastěj&scaron;&iacute;m v&yacute;sledkem pohlavn&iacute;ho v&yacute;běru je vznik různ&yacute;ch barevn&yacute;ch forem v r&aacute;mci jedn&eacute; populace.</p>
<h4>Disruptivn&iacute; selekce (ekologick&aacute; speciace)</h4>
<p>K ekologick&eacute; speciaci (disruptivn&iacute; selekci) doch&aacute;z&iacute; vlivem ekologick&eacute;ho faktoru. V př&iacute;padě abiotick&eacute;ho faktoru to je jeho n&aacute;hl&yacute; nedostatek či přebytek. U biotick&eacute;ho ekologick&eacute;ho faktoru (neboli biotick&eacute; interakce) jde nejčastěji o vliv kompetice mezi druhy či predace. Doch&aacute;z&iacute; pak k pos&iacute;len&iacute; př&iacute;rodn&iacute;ho v&yacute;běru.</p>
<h4>Chromozomov&eacute; změny (polyploidie)</h4>
<p>Polyploidie (chromozomov&aacute; změna) je situace, při n&iacute;ž norm&aacute;ln&iacute; diploidn&iacute; organismus z&iacute;sk&aacute; jednu nebo v&iacute;ce sad chromozomů nav&iacute;c. Počet chromozomov&yacute;ch sad je tak vy&scaron;&scaron;&iacute; než standardn&iacute; dvě.</p>
<p>U rostlin jsou chromozomov&eacute; změny velmi čast&eacute; a jsou jednou z nejčastěj&scaron;&iacute;ch př&iacute;čin jejich speciace. Naopak např&iacute;klad u savců způsobuje polyploidie vět&scaron;inou smrt, jednou z v&yacute;jimek je v&scaron;ak polyploidie u osm&aacute;ků.</p>
<h3>Parapatrick&aacute; speciace</h3>
<p>Parapatrick&aacute; speciace (parapatric speciation) je diferenciace populace vlivem siln&yacute;ch lok&aacute;ln&iacute;ch selekčn&iacute;ch tlaků na jednotliv&eacute; skupiny populace. Siln&aacute; geografick&aacute; variabilita dan&eacute;ho are&aacute;lu tak způsobuje speciaci.</p>
<p>Parapatrick&aacute; speciace je typick&aacute; zejm&eacute;na pro druhy, jejichž are&aacute;l roz&scaron;&iacute;řen&iacute; je velk&yacute; a na jehož lok&aacute;ln&iacute; skupiny tak působ&iacute; v&yacute;razně rozd&iacute;ln&eacute; životn&iacute; podm&iacute;nky.</p>
<h4>Prstencov&eacute; druhy</h4>
<p>Jako prstencov&eacute; druhy b&yacute;v&aacute; označov&aacute;n soubor propojen&yacute;ch populac&iacute;, kter&yacute; vznikl &scaron;&iacute;řen&iacute;m druhu okolo geografick&eacute; bari&eacute;ry. Mezi soused&iacute;c&iacute;mi populacemi prob&iacute;h&aacute; tok genů a jsou si tak velmi podobn&eacute;, mezi vz&aacute;jemně odlehl&yacute;mi populacemi je v&scaron;ak v&yacute;razně niž&scaron;&iacute; až nulov&yacute;.</p>
<p>Pokud koncov&eacute; populace prstence přijdou do kontaktu (dojde k "obejit&iacute;" přek&aacute;žky), mohou b&yacute;t již reprodukčně izolovan&eacute;.</p>
<hr />
<h2>Hybridn&iacute; a tenzn&iacute; z&oacute;ny</h2>
<p>Hybridn&iacute; a tenzn&iacute; z&oacute;ny jsou m&iacute;sta sekund&aacute;rn&iacute;ho kontaktu diferencovan&yacute;ch populac&iacute;. Reprodukčně izolačn&iacute; mechanismy mohou b&yacute;t v různ&eacute; f&aacute;zi:</p>
<ul>
<li>naddruhov&eacute; komplexy</li>
<li>dobře diferencovan&eacute; druhy</li>
<li>hůře diferencovan&eacute; druhy až poddruhy</li>
<li>morfologicky nerozli&scaron;iteln&eacute; populace</li>
</ul>
<p>Tenzn&iacute; z&oacute;ny se mohou roz&scaron;iřovat, pokud nefunguj&iacute; reprodukčně izolačn&iacute; mechanismy. Doch&aacute;z&iacute; v nich k difuzi druhů. Pokud v&scaron;ak v takov&eacute; z&oacute;ně izolačn&iacute; mechanismy funguj&iacute; a existuje v nich selekce proti hybridům, doch&aacute;z&iacute; k zužov&aacute;n&iacute; tenzn&iacute; z&oacute;ny a b&yacute;v&aacute; pak ostřeji vyj&aacute;dřen&aacute;.</p>
    <hr />
    <ImgCon><Biogeo /><div>
    <h2>Studijn&iacute; materi&aacute;ly Biogeografie</h2>
    <p>T&eacute;ma <strong>Speciace, její mechanismy a typy</strong> je souč&aacute;st&iacute; <Link to="/biogeografie/">studijn&iacute;ch materi&aacute;lů Biogeografie</Link>. Tento předmět byl vyučov&aacute;n na <a href="http://geogr.muni.cz">Geografick&eacute;m &uacute;stavu</a> <a href="http://muni.cz">Masarykovy univerzity</a>. Přejděte na rozcestn&iacute;k t&eacute;mat (1.&nbsp;tlač&iacute;tko), nebo si přečtěte předch&aacute;zej&iacute;c&iacute; (2.&nbsp;tlač&iacute;tko) či n&aacute;sleduj&iacute;c&iacute; (3. tlač&iacute;tko) t&eacute;ma.</p>
    <BtnWrap>
    <Link to="/biogeografie/"><button>Rozcestník biogeografie</button></Link>
    <Link to="/biogeografie/evolucni/koncepty-druhu/"><button className="inv">&larr; Koncepty pojetí druhu</button></Link>
    <Link to="/biogeografie/evolucni/makroevoluce/"><button className="inv">Makroevoluce &rarr;</button></Link>
    </BtnWrap>
    </div></ImgCon><hr />
  </Layout>
)
